/* eslint-disable max-len */
import { makeStyles } from '@mui/styles';
import Container from 'components/Container/Container';
import config from 'config';
import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'components/Img/404-hero.svg' o... Remove this comment to see the full error message
import BgSvg from 'components/Img/404-hero.svg';
import PaperCheckIcon from 'components/Icons/PaperCheck';
import ManagedIcon from 'components/Icons/Managed';
import MigrationsIcon from 'components/Icons/Migrations';
import PeaceIcon from 'components/Icons/Peace';
import CareIcon from 'components/Icons/Care';
import PowerIcon from 'components/Icons/Power';
import MinimunQuantityIcon from 'components/Icons/Infraestructure';
import { Link } from 'react-router-dom';

import { Typography, Grid, Paper } from '@mui/material';

const useStyles = makeStyles(
  (theme) => ({
    '@keyframes peaceicon': {
      '0%': {
        animationTimingFunction: 'ease-in, ease-in-out',
        transform: 'matrix(1,0,0,1,3,8)',
      },
      '50%': {
        animationTimingFunction: 'ease-in, ease-in-out',
        transform: 'matrix(1,0,0,1,3,17)',
      },
      '100%': {
        animationTimingFunction: 'ease-in, ease-in-out',
        transform: 'matrix(1,0,0,1,3,8)',
      },
    },
    bg: {
      display: 'flex',
      color: '#fff',
      backgroundImage: `url(${BgSvg})`,
      fallbacks: [
        {
          backgroundImage: 'linear-gradient(-30deg, #00004d 0%, #5333ed 49%, #2cd4d9 100%)',
        },
      ],
      minHeight: 800,
      width: 'calc(100% + 2px)',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    },
    container100: {
      height: '100%',
    },
    containerFeatures: {
      position: 'relative',
      top: -100,
      marginBottom: -100,
    },
    paperFeatures: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      padding: theme.spacing(10),
    },
    typoWithIcon: {
      display: 'inline-flex',
    },
    iconsWithText: {
      fontSize: 28,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      marginRight: theme.spacing(3),
    },
    containerSecond: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      marginTop: theme.spacing(12),
    },
    containerContentSecond: {
      textAlign: 'center',
    },
    peaceicon: {
      '& g > g > g > g:nth-child(2)': {
        animationName: '$peaceicon',
        animationDuration: '3s',
        animationIterationCount: 'infinite',
      },
    },
  }),
  { name: 'BringerNotFound' }
);

const NotFound = memo(
  () => {
    const classes = useStyles();
    const title = 'Page not found';
    // eslint-disable-next-line
    const description = 'Ship anywhere in the world with a click. Our online tools let you ship with confidence without downloading software';

    const ldjson = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: config.domain,
      name: config.app.title,
      alternateName: 'Bringer',
      image: `${config.domain}/bps-logo-white.png`,
      sameAs: [
        'https://www.facebook.com/Bringer',
        'https://instagram.com/Bringer',
        'https://www.linkedin.com/company/Bringer/',
        'https://twitter.com/@Bringer',
      ],
    };

    const ldjsonOrg = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: config.app.title,
      legalName: 'Bringer CORP',
      email: `mailto:contact@${config.host}`,
      url: config.domain,
      logo: `${config.domain}/bps-logo-white.png`,
      sameAs: [
        'https://www.facebook.com/Bringer',
        'https://instagram.com/Bringer',
        'https://www.linkedin.com/company/Bringer/',
        'https://twitter.com/@Bringer',
      ],
    };

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={`${config.domain}/`} />
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="twitter:title" content={title} />
          <meta name="og:description" content={description} />
          <meta name="twitter:description" content={description} />
          <meta property="og:type" content="website" />
          <meta name="robots" content="index, follow" />
          <script type="application/ld+json">{JSON.stringify(ldjson)}</script>
          <script type="application/ld+json">{JSON.stringify(ldjsonOrg)}</script>
        </Helmet>
        <div className={classes.bg}>
          <Container>
            <Grid container alignItems="center" className={classes.container100}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h3" component="h1" color="inherit" gutterBottom>
                  Page not found
                </Typography>
                <Typography variant="h5">
                  We can’t find the page you’re looking for. Go to
                  <Link to="/">main page</Link>.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className={classes.containerFeatures}>
          <Container>
            <Paper className={classes.paperFeatures}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h5">Growing your business?</Typography>
                  <Typography variant="subtitle1">
                    Scale your operations by integrating with our API!
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography gutterBottom className={classes.typoWithIcon}>
                    <PaperCheckIcon className={classes.iconsWithText} />
                    Print shipping labels online
                  </Typography>
                  <Typography className={classes.typoWithIcon}>
                    <ManagedIcon className={classes.iconsWithText} />
                    No confusing customs forms
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography gutterBottom className={classes.typoWithIcon}>
                    <MigrationsIcon className={classes.iconsWithText} />
                    Full tracking transparency
                  </Typography>
                  <Typography className={classes.typoWithIcon}>
                    <MinimunQuantityIcon className={classes.iconsWithText} />
                    No minimum quantity
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </div>
        <Container>
          <Grid container className={classes.containerSecond}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                BPS Works For You
              </Typography>
              <Typography variant="subtitle1" align="center">
                Our clients love us because we never settle on these
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={12} sm={3} className={classes.containerContentSecond}>
                  <PeaceIcon className={classes.peaceicon} />
                  <Typography variant="h6" align="center">
                    Peace on Mind
                  </Typography>
                  <Typography variant="subtitle1" align="center">
                    You’re free to focus on growing your business, let us handle your site. Always
                    running, monitored, and scaling on-demand.
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3} className={classes.containerContentSecond}>
                  <CareIcon className={classes.careicon} />
                  <Typography variant="h6" align="center">
                    Support that cares
                  </Typography>
                  <Typography variant="subtitle1" align="center">
                    Support is our number one priority. Pro-active Shipping experts at your service,
                    who reach out when your attention is needed.
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3} className={classes.containerContentSecond}>
                  <PowerIcon className={classes.powerfullicon} />
                  <Typography variant="h6" align="center">
                    User-friendly, yet powerful
                  </Typography>
                  <Typography variant="subtitle1" align="center">
                    Designed with beginners in mind and packed with advanced features developers
                    will love. Start with a quick and simple install, or use our pro solutions right
                    away.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <br />
        <br />
      </>
    );
  },
  () => true
);

export default NotFound;
